<template>
  <div>
    <div v-show="!loading">
      <div class="notify" v-if="Object.keys(this.answer).length >= 6" @click="handleEndTest">
        系统已经可以计算你的得分，点击此处结束测试 >
      </div>
      <div class="container">
        <div class="header">
          <p>计时已开始，请勿中途退出</p>

          <van-button
              round
              class="question-card"
              @click="visible = true"
          >
            答题卡
          </van-button>
        </div>

        <div class="questionCard">
          <div class="questionStem" ref="questionStem"/>

          <div
              class="questionOption"
              v-show="curQuestionData['答题方式'] === '单选'"
              ref="select-item"
          />
        </div>

        <div class="tools">
          <van-button round class="pre-btn" v-if="index !== 1" @click="handleStep('pre')">上一题</van-button>
          <van-button round class="next-btn" v-if="index !== questionNum" @click="handleStep('next')">下一题</van-button>
          <van-button round class="end-btn" @click="handleEndTest" v-else>结束测试</van-button>
        </div>

        <question-card
            :visible="visible"
            :question-num="questionNum"
            :cur-num="index"
            :handle-nums="handleNums"
            @close="handleClose"
            @onQuestionClick="handleQuestionClick"
        />
      </div>
    </div>

    <div class="loading" v-show="loading">
      <template v-if="hasMobile">
        <img src="@/assets/img/activity/loading.gif"/>
        <p>正在分析</p>
      </template>

      <div class="submitMobile" v-else>
        <img src="@/assets/img/activity/get-result-banner.png"/>

        <div class="submitMobile__desc">
          <p>为确保统计结果的准确</p>
          <p>请输入手机号关联唯一成绩</p>
        </div>

        <van-field v-model="mobile" type="tel" placeholder="请输入手机号"/>

        <van-button round class="submitBtn" @click="submitMobile" type="primary">查看结果</van-button>
      </div>
    </div>

  </div>
</template>

<script type="application/ecmascript">
import { Toast, Dialog } from 'vant';
import QuestionCard from './component/QuestionCard';
import QuestionTopic from '@/views/special/component/topic';
import { mapState } from 'vuex';
import wxSDKMixins from "@/views/activity/mixins";

export default {
  components: {QuestionCard, QuestionTopic},
  mixins: [wxSDKMixins],
  data () {
    return {
      loading: false, // 加载中
      visible: false, // 答题卡弹框
      hasMobile: true, // 无手机号
      index: 1,
      time: 0,
      handleNums: [],
      answer: {}, // 所有题目答案{ idx: answer }
      isRight: {}, // 所有题目正确与否 { idx: boolean }
      mobile: '', // 活动手机号
    }
  },
  computed: {
    ...mapState({
      activityQuestions: state => state.activityQuestions,
      activityMobile: state => state.activityMobile
    }),
    questionNum () {
      return this.activityQuestions.length
    },
    curQuestionData () {
      return this.activityQuestions[ this.index - 1 ] || {}
    }
  },
  watch: {
    curQuestionData () {
      this.renderQuestion()
      this.renderQuestionOption()
    }
  },
  methods: {
    init () {
      this.renderQuestion()
      this.renderQuestionOption()
    },

    // 绘制题目
    renderQuestion () {
      const type = this.curQuestionData[ '答题方式' ]

      this.$refs.questionStem.innerHTML = ''
      this.curQuestionData[ '例题' ].map(item => {
        const dom = document.createElement('span');
        switch (type) {
          case '填空':
            dom.setAttribute('style', 'display: inline-block;');
            let str = item[ 1 ]
            let inputDom = document.createElement('input');
            inputDom.className = 'inputAnswer'
            if (item[ 1 ].indexOf('冇') > 0) {
              inputDom.value = this.answer[ this.curQuestionData.idx ] || null
              str = item[ 1 ].replace('冇', '');
            }
            this.$kx.render(str, dom, {
              displayMode: true,
            })
            this.$refs.questionStem.appendChild(dom);
            if (item[ 1 ].indexOf('冇') > 0) {
              this.$refs.questionStem.appendChild(inputDom);
            }
            break;
          case '单选':
            dom.setAttribute('style', 'display: inline-block;')
            this.$kx.render(item[ 1 ], dom, {
              displayMode: true,
            })
            this.$refs.questionStem.appendChild(dom);
            break;
          default:
            break
        }
      });
    },

    renderQuestionOption () {
      const type = this.curQuestionData[ '答题方式' ]
      if (type !== '单选') {
        return
      }
      this.$refs[ 'select-item' ].innerHTML = ''
      this.curQuestionData[ '选项' ].map((item, optionIndex) => {
        const dom = document.createElement('div');
        this.$kx.render(item, dom, {
          displayMode: true,
        })
        dom.className = 'questionOption__item';
        if (this.answer[ this.curQuestionData.idx ] === item) {
          dom.classList.add('active');
        }
        dom.onclick = () => {
          const activeDom = document.querySelector('.active');
          if (activeDom) {
            activeDom.classList.remove('active');
          }
          dom.classList.add('active');
          const isRight = this.curQuestionData[ '解' ][ optionIndex ]
          this.handleSelectOption(this.curQuestionData.idx, item, isRight)
        }
        this.$refs[ 'select-item' ].appendChild(dom);
      });
    },

    handleSelectOption (idx, option, isRight) {
      this.answer[ idx ] = option
      this.isRight[ idx ] = isRight
    },

    handleClose () {
      this.visible = false
    },
    handleQuestionClick (index) {
      if (!this.handleNums.includes(index + 1)) {
        return
      }
      this.visible = false
      this.index = index + 1
    },

    handleStep (type) {
      const applyType = this.curQuestionData[ '答题方式' ]
      if (applyType === '填空') {
        const value = document.querySelector('.inputAnswer').value
        this.answer[ this.curQuestionData.idx ] = value
        this.isRight[ this.curQuestionData.idx ] = this.curQuestionData[ '解' ][ 0 ] === value
      }

      if (type === 'pre') {
        this.index --
        return
      }
      if (type === 'next' && this.answer[ this.curQuestionData.idx ]) {
        if (!this.handleNums.includes(this.index)) {
          this.handleNums.push(this.index)
        }
        this.index ++
      } else {
        Toast(`请${applyType === '填空' ? '填写': '选择'}答案`);
      }
    },
    // 结束测试
    async handleEndTest () {
      const applyType = this.curQuestionData[ '答题方式' ]
      if (applyType === '填空') {
        const value = document.querySelector('.inputAnswer').value
        this.answer[ this.curQuestionData.idx ] = value
        this.isRight[ this.curQuestionData.idx ] = this.curQuestionData[ '解' ][ 0 ] === value
      }
      this.loading = true
      setTimeout(() => {
        this.hasMobile = false
      }, [2000]);
    },

    // 提交手机号
    async submitMobile () {
      const phoneRegexp = /^(1[3-9][0-9])\d{8}$/
      if (!phoneRegexp.test(this.mobile)) {
        Toast('请输入正确的手机号');
        return
      }
      const ret = await this.$api.activityGetResult({
        param: {
          username: this.mobile
        }
      });
      if (ret.status === 301) {
        this.submitForm(this.mobile);
      } else {
        Dialog.confirm({
          title: ' ',
          message: '该手机号已关联成绩\n本次成绩将不计入排名数据',
          confirmButtonText: '知道了',
          confirmButtonColor: '#7387d9',
        }).then(() => {
          this.submitForm(this.mobile);
        }).catch(() => {

        });
      }
    },

    async submitForm (mobile) {
      this.time = + new Date() - this.time
      try {
        const ret = await this.$api.activitySubmitAnswer({
          param: {
            exam_seq: JSON.stringify(this.isRight), // 正确与否
            userID: mobile,
            spend: parseInt(this.time / 1000),
            timestamp: JSON.stringify(this.answer), // 用户答案
          },
          method: 'post'
        });
        this.$store.commit('setExaminationTime', parseInt(this.time / 1000));
        this.$router.push(`/activity-result?mobile=${mobile}`);
      } catch (err) {
        console.error(err)
      }
    },
    fetchQuestions () {
      this.$store.commit('queryActivityQuestions');
    }
  },
  mounted () {
    this.fetchQuestions()
    this.initShare()
    this.$nextTick(() => {
      setTimeout(() => {
        this.init()
        this.time = + new Date()
      }, 1000)
    })
  }
}
</script>

<style lang="less" scoped>
.loading {
  height: 100vh;
  width: 100vw;
  background: #2A3155;

  > img {
    width: 72px;
    height: 72px;
    margin-top: 227px;
  }

  > p {
    margin-top: 24px;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
  }
}

.notify {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #FF7F44;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  color: #FFFFFF;
}

.container {
  min-height: calc(100vh - 48px);
  padding: 24px 16px;
  background: #F7F8FB;

  .header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

    > p {
      height: 19px;
      font-size: 14px;
      color: #9498AA;
      line-height: 19px;
    }

    .question-card {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #2A3155;
      line-height: 26px;
      height: 26px;
      background: #F7F8FB;
      border-radius: 13px;
      border: 1px solid #B9BFD3;
    }
  }

  .questionCard {
    .questionStem {
      text-align: left;

      /deep/ .inputAnswer {
        margin: 0 4px;
        width: 40px;
        border: 1px solid #B9BFD3;
      }
    }

    .questionOption {
      /deep/ &__item {
        width: 343px;
        padding: 8px 0px;
        font-size: 18px;
        margin: 0 auto 12px;
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid #E6E8EF;
        text-align: center;
      }

      /deep/ .katex-display {
        margin: 0;
      }

      /deep/ .katex-html {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      /deep/ .active {
        border: 1px solid #415FD5;
        color: #415FD5;
      }
    }
  }

  .tools {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    margin-top: 26px;

    .pre-btn, .next-btn, .end-btn {
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 40px;
      border-radius: 24px;
      height: 40px;
    }

    .pre-btn {
      width: 120px;
      background: #415FD5;
      margin-right: 10px;
    }

    .next-btn {
      width: 221px;
      background: #F9C200;
    }

    .end-btn {
      width: 221px;
      background: #FF7F44;
    }
  }
}

.submitMobile {
  padding: 49px 42px;

  > img {
    width: 132px;
    margin-bottom: 45px;
  }

  &__desc {
    margin-bottom: 24px;

    > p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #F7F8FB;
      line-height: 30px;
    }
  }

  .submitBtn {
    width: 289px;
    height: 40px;
    background: #F9C200;
    font-size: 18px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 40px;
    margin: 24px 0 80px;
    border: none;
  }
}
</style>
