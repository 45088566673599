<template>
  <van-overlay :show="visible">
    <div class="content">
      <p class="title">答题卡</p>
      <div class="questions">
        <div
            :key="index"
            v-for="(item, index) in questionNum"
            :class="'questions__item ' +  questionType(index + 1)"
            @click="onQuestionClick(index)"
        >
          {{ index + 1 }}
        </div>
      </div>

      <van-icon size="40" color="#6d6f70" name="clear" @click="close" />
    </div>
  </van-overlay>
</template>

<script type="application/ecmascript">
export default {
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    // 题数
    questionNum: {
      type: Number,
      default: () => 0
    },
    // 当前答题位置
    curNum: {
      type: Number,
      default: () => 0
    },
    // 正确题目
    rightNums: {
      type: Array,
      default: () => []
    },
    // 错误题目
    errorNums: {
      type: Array,
      default: () => []
    },
    // 做过的题目
    handleNums: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {}
  },
  methods: {
    questionType (index) {
      if (this.curNum === index) {
        return 'current'
      }
      if (this.rightNums.includes(index)) {
        return 'right'
      }
      if (this.errorNums.includes(index)) {
        return 'error'
      }
      if (this.handleNums.includes(index)) {
        return 'handle'
      }
      return ''
    },
    // 关闭
    close () {
      this.$emit('close');
    },
    // 点击题目
    onQuestionClick (index) {
      this.$emit('onQuestionClick', index)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  margin-top: 121px;

  .title {
    font-size: 18px;
    font-weight: 600;
    color: #F7F8FB;
  }

  .questions {
    margin: 37px 18px 77px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    flex-wrap: wrap;
    &__item {
      width: 100px;
      height: 40px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 4px;
      border: 2px solid #FFFFFF;
      margin-bottom: 18px;
      text-align: center;
      line-height: 40px;
      font-size: 18px;
      font-weight: 600;
      color: #F7F8FB;

      &.right {
        background: rgba(21, 195, 146, 0.3);
        border-color: #15C392;
      }
      &.current {
        background: #415FD5;
        border-color: #415FD5;
      }
      &.error {
        background: rgba(255, 66, 54, 0.3);
        border-color: #FF4236;
      }
      &.handle {
        background: rgba(65, 95, 213, 0.2);
        border-color: #415FD5;
      }
    }
  }
}
</style>
